body {
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* max-width: 100vw; */
  /* position: absolute; */
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

html{
  overflow-x: hidden;
  /* margin: 0 auto; */
  /* max-width: "100vw"; */
  /* position: relative; THIS PROBABLY BROKE SHIT */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiGrid-root form {
  color: black;
}
/* .MuiGrid-root a { */
  /* display: none; */
/* } */
.MuiGrid-root input {
  border-radius: 0px;
  border-color: lightgrey;
}
.MuiGrid-root input:focus {
  border-radius: 0px !important;
  border-color: lightgrey !important;
}
input:focus {
  border: 1px solid grey !important;
}

select {
  border-radius: 0px !important;
  border: 1px solid lightgrey !important;
  height: 40px
}